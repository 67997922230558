import { Container } from "react-bootstrap";
import Images from '../Data/imageSet';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'animate.css';

export const MobileAppScreen = () => {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 650 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 650, min: 0 },
          items: 1
        }
      };

  return (
    <section className="mobileApp" id="mobileApp">
      <Container>
             <div className="row">
                <div className="col-12">
                    <div className="wow zoomIn mobileapp-slider">
                        <h2 className="mb-4">AI SUPERWALLET APP</h2>
                        <h5>GPT3- our app is designed and specialised to gather users feedback and help them make decisions and increase their engagement.</h5>
                        <Carousel autoPlay={true} responsive={responsive} swipeable={true} infinite={true} draggable={true} arrows={true} className="owl-carousel owl-theme">
                            <div className="item">
                                <img src={Images.mobileScreen1} alt="Image1" />
                            </div>
                            <div className="item">
                                <img src={Images.mobileScreen2} alt="Image2" />
                            </div>
                            <div className="item">
                                <img src={Images.mobileScreen3} alt="Image3" />
                            </div>
                            <div className="item">
                                <img src={Images.mobileScreen4} alt="Image3" />
                            </div>
                            <div className="item">
                                <img src={Images.mobileScreen5} alt="Image3" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
            <div style={{marginTop: "30px"}}><a href="https://drive.google.com/file/d/1KaqhSpk1hKo35FojYZQmZ3VVuy9sOwrI/view?usp=share_link" className="buttonWhitepaper mt-4" target="_blank" rel="noopener">Whitepaper</a></div>
      </Container>
    </section>
  );
};
