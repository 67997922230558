import Images from '../Data/imageSet';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Tokenomics = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1
    }
  };

  return (
    <section className="tokenomic" id="tokenomics">
        <div className="container">
        <TrackVisibility>
              <div>
                <img className="floating-coins1" src={Images.coinsImg} alt="CoinsImg"/>
              </div>
          </TrackVisibility>
          <TrackVisibility>
              <div>
                <img className="floating-coins2" src={Images.coinsImg} alt="CoinsImg"/>
              </div>
          </TrackVisibility>
            <div className="row">
                <div className="col-12">
                    <div className="tokenomic-bx wow zoomIn">
                        <h2 className="mb-4">TOKENOMICS</h2>
                        <Carousel autoPlay={true} responsive={responsive} infinite={true} className="owl-carousel owl-theme tokenomic-slider">
                            <div className="item">
                                <img src={Images.meter1} alt="Image1" />
                                <h5>PRESALE + LIQUIDITY</h5>
                            </div>
                            <div className="item">
                                <img src={Images.meter2} alt="Image2" />
                                <h5>DEVELOPMENT</h5>
                            </div>
                            <div className="item">
                                <img src={Images.meter3} alt="Image3" />
                                <h5>CEX LISTING</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
