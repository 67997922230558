// Nav Icons
import logo from '../assets/img/Superwallet-LOGO2.png';
import navIcon1 from '../assets/img/nav-icon4.svg';
import navIcon2 from '../assets/img/nav-icon5.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

// Banner Images
import headerImg from "../assets/img/header-img.svg";

// About Images
import colorSharp1 from "../assets/img/color-sharp.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import userImage from "../assets/img/user1.png";
import userImage2 from "../assets/img/user2.png";

// Gallery Images
import projImg1 from "../assets/img/Frame 1.png";
import projImg2 from "../assets/img/Frame 2.png";
import projImg3 from "../assets/img/Frame 3.png";
import projImg4 from "../assets/img/Frame 4.png";
import projImg5 from "../assets/img/Frame 5.png";
import projImg6 from "../assets/img/Frame 6.png";

// Mobile App Screens
import mobileScreen1 from "../assets/img/mobile-screen-slider1.png";
import mobileScreen2 from "../assets/img/mobile-screen-slider2.png";
import mobileScreen3 from "../assets/img/mobile-screen-slider3.png";
import mobileScreen4 from "../assets/img/mobile-screen-slider4.png";
import mobileScreen5 from "../assets/img/mobile-screen-slider5.png";

// Tokenomics Images
import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import coinsImg from "../assets/img/3D-coins2.png";

// Contact Images
import contactImg from "../assets/img/contact-img.svg";

const Images = {
    logo,
    navIcon1,
    navIcon2,
    navIcon3,
    headerImg,
    colorSharp1,
    colorSharp2,
    userImage,
    userImage2,
    projImg1,
    projImg2,
    projImg3,
    projImg4,
    projImg5,
    projImg6,
    mobileScreen1,
    mobileScreen2,
    mobileScreen3,
    mobileScreen4,
    mobileScreen5,
    meter1,
    meter2,
    meter3,
    coinsImg,
    contactImg,
};

export default Images;