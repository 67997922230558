import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { Fade } from "react-reveal";
import { projects } from "../Data/dataSet";

export const Utilities = () => {
  return (
    <section className="project" id="utilities">
      <Container>
        <Row>
          <Col size={12}>
            <div className="tabPane-Group">
              <TrackVisibility>
                {({ isVisible }) => (
                  <div
                    
                  >
                    <h2>ZERO FEES ON AI SUPERWALLET</h2>
                    <Tab.Container id="projects-tabs" defaultActiveKey="first">
                      <Nav
                        variant="pills"
                        className="nav-pills mb-5 justify-content-center align-items-center"
                        id="pills-tab"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="first">
                            Lightning fast service
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">
                            Super wallet chain
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">
                            Multi-sig support
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content
                        id="slideInUp"
                        className={
                          isVisible
                            ? "animate__animated animate__slideInUp"
                            : ""
                        }
                      >
                        <Tab.Pane eventKey="first">
                          <p style={{ textAlign: "justify" }}>
                            <span style={{ fontSize: "30px" }}>A</span> crypto 
                            wallet provides a secure way to store your cryptocurrency, 
                            send, and receive it. You can also track your crypto 
                            balance and transactions and swap one cryptocurrency for another.
                            <br></br>- We provide fastest service ever in a digital wallet like
                            Authorization, Multi-Currency Support, Push Notification, 
                            Real-Time Updates & many more.
                            <br></br>- On a glance you can do lot more with the application
                            like Swap, Exchange or Sell. 
                          </p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <p style={{ textAlign: "justify" }}>
                            <span style={{ fontSize: "30px" }}>W</span>e use
                            wallets to store our tangible money. SuperWallet is
                            the same — you need a digital wallet to store your
                            digital currency securely.
                            <br></br>- whether the SuperWallet will allow you to
                            hold your funds safely in cold storage, which is
                            deemed the highest level of security.
                            <br></br>- The SuperWallet can allow you to to
                            exchange between fiat and crypto, allowing you ease
                            when buying and selling.
                          </p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <p style={{ textAlign: "justify" }}>
                            <span style={{ fontSize: "30px" }}>M</span>ultisig
                            technology was created in 2012 as people saw a need
                            to share an account with one or multiple persons
                            securely. The Bitcoin blockchain was the first chain
                            to implement multisig addresses, and so, multisig
                            wallets were born the year after.
                            <br></br>- A multisig wallet allows you to sign a
                            transaction as a group. Consequently, we need to
                            provide multiple unique signatures to transfer
                            funds.
                            <br></br>- Let's compare a multisig wallet with a
                            vault that requires two keys to unlock it.
                            Therefore, you need both keys at once to open the
                            vault. If you brought one key, you can’t access your
                            funds.
                          </p>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                )}
              </TrackVisibility>
            </div>
          </Col>
        </Row>
        <Fade bottom big cascade>
          <Row>
            {projects.map((project, index) => {
              return <ProjectCard key={index} {...project} />;
            })}
          </Row>
        </Fade>
      </Container>
    </section>
  );
};
