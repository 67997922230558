import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { featureList } from "../Data/dataSet";
export const Features = () => {
  return (
    <section className="feature" id="feature">
      <h2>WALLET FEATURES</h2>
      <div className="wallet-feature">
        <span className="shooting-stars"></span>
        <span className="shooting-stars"></span>
        <span className="shooting-stars"></span>
        <span className="shooting-stars"></span>
        <span className="shooting-stars"></span>
        <span className="shooting-stars"></span>
        <span className="shooting-stars"></span>
        <span className="shooting-stars"></span>
        <span className="shooting-stars"></span>
        <span className="shooting-stars"></span>
      </div>
      <Container>
        <Row className="">
          {featureList.map((content, index) => {
            return (
              <Col md={6} xl={4} key={index}>
                <div className="featureCard">
                  <Row>
                    <Col md={12}>
                      <div>
                        <h4>{content.title}</h4>
                        <h6>{content.text}</h6>
                        <h6>{content.text2}</h6>
                        <h6>{content.text3}</h6>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};