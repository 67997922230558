import { Container } from "react-bootstrap";
import "animate.css";
import Images from "../Data/imageSet";
import { roadMaps } from "../Data/dataSet";
import {Fade} from "react-reveal";

export const RoadMap = () => {
  return (
    <section className="roadmap" id="roadmap">
      <Container>
      <Fade bottom cascade>
        <div className="title">
        <h2>ROADMAP</h2>
        </div>
        </Fade>
        <div class="history-tl-container">
          <ul class="tl">
          {roadMaps.map((content, index) => {
              return (
                <li class="tl-item" ng-repeat="item in retailer_history" key={index}>
                  <div class="timestamp">
                    {content.stepCount}
                  </div>
                <div class="item-title">{content.title}</div>
            </li>
              );
            })}
          </ul>
        </div>
      </Container>
      <img className="floating-blurr-img2" src={Images.colorSharp2} alt="blurryImage"></img>
      <img className="floating-blurr-img1" src={Images.colorSharp1} alt="blurryImage2"></img>
    </section>
  );
};
