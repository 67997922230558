import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import Images from "../Data/imageSet";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} xs={12} md={5}>
            <img className="footer-logo" src={Images.logo} alt="Logo" />
          </Col>
          <Col size={12} xs={12} md={7} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://twitter.com/AISuperwallet" target="_blank" rel="noopener noreferrer"><img src={Images.navIcon1} alt="twitter-icon" /></a>
              <a href="https://t.me/AISUPERWALLET"  target="_blank" rel="noopener noreferrer"><img src={Images.navIcon2} alt="telegram-icon" /></a>
              <a href="#?"><img src={Images.navIcon3} alt="Icon" /></a>
            </div>
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
