import { Container, Row, Col } from "react-bootstrap";
import Images from "../Data/imageSet";
import "animate.css";
import {Fade, Zoom, Bounce} from "react-reveal";

export const AboutUs = () => {
  return (
    <section className="project" id="projects">
      <Container>
        <Row style={{ marginBottom: "100px" }}>
          <Col xl={{span: 6, order: 1}} sm={{span: 12, order: 1}} md={{span: 12, order: 1}}>
            <div className="about-content-img">
            <Zoom>
              <div>
                <img
                  className="animate__animated animate__zoomIn"
                  src={Images.userImage}
                  alt="user"
                ></img>
              </div>
            </Zoom>
            </div>
          </Col>
          <Col xl={{span: 6, order: 2}} sm={{span: 12, order: 2}}  md={{span: 12, order: 2}}>
            <Fade right>
              <div className="about-content">
              <h3>
                The Safest Wallet - About Us
              </h3>
              <h5
              >
                &nbsp;&nbsp;&nbsp;&nbsp; AI Super wallet has Multi-Sig Support, Hardware Wallet Integration, and built-in exchanges. <br></br>
Super Wallet is a Web 5.0 project, One stop crypto solution with decentralized application, Staking, Swap, Multi-Chain mobile wallet app both on Android and Ios platform, Own CEX, Own Super Wallet Chain and many more utilities.
              </h5>
              <Bounce right cascade>
                <ul>
                  <li>Super wallet has Multi-Sig Support</li>
                  <li>Hardware Wallet Integration</li>
                  <li>Built-in exchanges</li>
                </ul>
              </Bounce>
              </div>
            </Fade>
          </Col>
        </Row>
        <Row style={{ marginBottom: "100px" }}>
          <Col xl={{span: 6, order: 1}} md={{span:12, order: "last"}} xs={{span: 12, order: "last"}}>
            <Fade left>
              <div className="about-content">
              <h3>Why we?</h3>
            <h5>
              &nbsp;&nbsp;&nbsp;&nbsp; The Identity Theft Resource Center says
              that digital wallets rely on proven security measures such as
              two-factor authentication and PINs that can only be used once.
              Wallets also use encryption technology to scramble the financial
              and password information of users.
            </h5>
            <Bounce left cascade>
            <ul>
              <li>Buy crypto with card</li>
              <li>Store your assets on Super Wallet Chain with Super wallet</li>
              <li>Built-in exchanges</li>
              <li>Easy Interface</li>
              <li>Private and secure</li>
            </ul>
            </Bounce>
              </div>
            </Fade>
          </Col>
          <Col xl={{span: 6, order: 2}} md={{span:12, order: "first"}} xs={{span: 12, order: "first"}}>
          <div className="about-content-img">
            <Zoom right>
            <div>
              <img
                className="animate__animated animate__backInLeft"
                src={Images.userImage2}
                alt="user"
              ></img>
            </div>
            </Zoom>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={Images.colorSharp2} alt="blurryImage"></img>
      <img className="blurr-image-left" src={Images.colorSharp1} alt="blurryImage2"></img>
    </section>
  );
};
