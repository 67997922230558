import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState} from "react";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Tokenomics } from "./components/Tokenomics";
import { AboutUs } from "./components/About";
import { RoadMap } from "./components/RoadMap";
import { Features } from "./components/feature";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import AnimatedCursor from "react-animated-cursor";
import { Fragment } from 'react';
import { Utilities } from './components/Utilities';
import { MobileAppScreen } from './components/MobileAppScreen';

function App() {
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(()=>{
      spinner.style.display = "none";
      setLoading(false);
    },3000)
  }
  return (
    !loading && (
    <div className="App">
      <Fragment>
      <AnimatedCursor
          innerSize={10}
          outerSize={40}
          color="128, 255, 0"
          outerAlpha={0.4}
          innerScale={0.6}
          outerScale={2}
          trailingSpeed={10}
          hasBlendMode={true}
          innerStyle={{
            zIndex: 10000,
            color:"#80FF00"
          }}
          outerStyle={{
            border: "1.5px solid white",
            backgroundColor: "#BABBB950",
            zIndex: 1002
          }}
        />
      <NavBar />
      <Banner />
      <AboutUs />
      <Features />
      <MobileAppScreen />
      <Utilities />
      <RoadMap />
      <Tokenomics />
      <Contact />
      <Footer />
      </Fragment>
    </div>
    )
  );
}

export default App;


/*
1. Add content properly.
*/