import Images from "../Data/imageSet";

export const featureList = [
  {
    title: "Multi-sig support",
    text: "This allows multiple users to sign a transaction before it can be executed, providing an additional layer of security.",
  },
  {
    title: "Hardware wallet integration",
    text: "This allows users to store their private keys on a separate, offline device, making it much more difficult for hackers to steal them.",
  },
  {
    title: "Built-in exchanges",
    text: "This allows users to buy, sell and trade cryptocurrencies directly from their wallet, without the need to transfer funds to an exchange.",
  },
  {
    title: "Advanced security features",
    text: "Biometric authentication, 2FA, password recovery, and PIN code Lend and Earn yield on your Crypto.",
  },
  {
    title: "User-friendly interface",
    text: "Our super crypto wallet is designed to use and navigate, even for those who are not technically skilled.",
  },
  {
    title: "Secure and fastest inbuilt Browser-Access",
    text: "Powerful Secure and fastest inbuilt Browser-Access Defi DApps through our Extension, manage multichain NFTS, get best swap rate.",
  },
  {
    title: "Portfolio management",
    text: "1. A DEX aggregator to give you best price across all exchanges.",
    text2:
      "2. Algo based suggestions for auto trading. Just select the Algo and your trades will happen for you automatically.",
    text3: "3. Stay Updated with all market news and updates.",
  },
  {
    title: "Reduced transaction fees",
    text: "More the Super wallet token you hold lesser the transaction fees you have to pay.",
  },
  {
    title: "Increased withdrawal limits",
    text: "More the Super wallet token you hold larger the amounts of cryptos you can withdraw.",
  },
];

export const roadMaps = [
  {
    title: "Contract audit",
    stepCount: "STEP : 1",
  },
  {
    title: "Team Doxxing and Kyc",
    stepCount: "STEP : 2",
  },
  {
    title: "Launch on pancakeswap",
    stepCount: "STEP : 3",
  },
  {
    title: "Ownership Renounced",
    stepCount: "STEP : 4",
  },
  {
    title: "Contests and Giveaways",
    stepCount: "STEP : 5",
  },
  {
    title: "Coinmarketcap Listing",
    stepCount: "STEP : 6",
  },
  {
    title: "First CEX listing",
    stepCount: "STEP : 7",
  },
  {
    title: "Influencers onboarding",
    stepCount: "STEP : 8",
  },
  {
    title: "Launching SuperWAllet Exchange with Futures",
    stepCount: "STEP : 9",
  },
  {
    title: "Supper Wallet update and more feature addition",
    stepCount: "STEP : 10",
  },
  {
    title: "Second CEX listing",
    stepCount: "STEP : 11",
  },
  {
    title: "Launching Supper WAllet NFTs marketplace",
    stepCount: "STEP : 12",
  },
];

export const projects = [
  {
    title: "Super Wallet Chain",
    description: "Super Wallet Chain",
    imgUrl: Images.projImg1,
  },
  {
    title: "DAPP (web 5.0)",
    description: "DAPP (web 5.0)",
    imgUrl: Images.projImg4,
  },
  {
    title: "Exchange (CEX)",
    description: "Exchange (CEX)",
    imgUrl: Images.projImg6,
  },
  {
    title: "STAKE",
    description: "STAKE",
    imgUrl: Images.projImg2,
  },
  {
    title: "SWAP",
    description: "SWAP",
    imgUrl: Images.projImg5,
  },
  {
    title: "Coin Screener (Hot-coins)",
    description: "Coin Screener (Hot-coins)",
    imgUrl: Images.projImg3,
  },
];
