import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import Images from "../Data/imageSet";
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="xl" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="#home">
            <img src={Images.logo} alt="Logo" style={{width: scrolled ? "100px" : "130px"}}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === '#home' ? 'active navbar-link' : 'navbar-link'}>Home</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>About Us</Nav.Link>
              <Nav.Link href="#feature" className={activeLink === 'feature' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('feature')}>Features</Nav.Link>
              <Nav.Link href="#utilities" className={activeLink === 'utilities' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('utilities')}>Utilities</Nav.Link>
              <Nav.Link href="#roadmap" className={activeLink === 'roadmap' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('roadmap')}>RoadMap</Nav.Link>
              <Nav.Link href="#tokenomics" className={activeLink === 'tokenomics' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('tokenomics')}>Tokenomics</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://twitter.com/AISuperwallet" target="_blank" rel="noopener noreferrer"><img src={Images.navIcon1} alt="twiiter-icon" /></a>
                <a href="https://t.me/AISUPERWALLET" target="_blank" rel="noopener noreferrer"><img src={Images.navIcon2} alt="telegram-icon" /></a>
                <a href="#?" target="_blank" rel="noopener noreferrer"><img src={Images.navIcon3} alt="instagram-icon" /></a>
              </div>
              <HashLink to='#connect'>
                <button className="vvd"><span>Connect With Us</span></button>
              </HashLink>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
